import request from './request.js'
export function intelliDiagnose(params) {
    return request({
		url:"card/intelliDiagnose.do?",
        method: 'post',
        data: params
    })
}
export function add4remark(params) {
    return request({
		url:'/order/agentwechatorder/add4remark.do?',
        method: 'post',
        data: params
    })
}





